
















@use "sass:math";
@import '@design';

$hover-border-w: 2px;
.menu-item {
  position: relative;
  transition: background-color 0.2s ease, transform 0.2s ease;
  &.standard {
    .menu-item--top {
      > * {
        line-height: 1;
      }
    }
  }

  .menu-item--title,
  .menu-item--prices {
    letter-spacing: 0.02em;
  }
  .menu-item--description {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.3;
  }
  &.sortable-ghost {
    background-color: rgba($color-db-green, 0.2);
    opacity: 0.4;
  }
  &.showing-ui {
    &.hovering,
    &.selected {
      $gutter: 20px;

      padding-right: $gutter;
      padding-left: math.div($gutter, 2);
      margin-right: -$gutter;
      margin-left: math.div(-$gutter, 2);
    }
  }
  &.hovering.showing-ui {
    background-color: rgba($color-db-teal, 0.5);
  }
  &.selected {
    background-color: rgba($color-db-green, 0.1);
  }
  &.delete-hover {
    background: rgba(red, 0.075) !important;
  }
}

.menu-element--dots {
  border-bottom: 2px dotted #000;
}

.drag-handle--wrap {
  position: absolute;
  top: 50%;
  right: 0;
  min-width: 20px;
  height: 100%;
  transform: translateY(-50%);
  .drag-handle {
    font-size: 18px;
    cursor: pointer;
  }
}
